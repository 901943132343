import { GraphicData } from '@/classes/stats/GraphicData'
import { Colors } from '@/classes/colors/Colors'
export class SmsGraphicData {
  smsCalculatedData = null
  thatContext = null
  interactionItems = []

  constructor (smsCalculatedData, thatContext) {
    this.smsCalculatedData = smsCalculatedData
    this.thatContext = thatContext
  }

  setInteractionItems (interactionItems) {
    this.interactionItems = interactionItems
  }

  getCardsData () {
    return [
      {
        title: this.thatContext.$t('Enviados').toString(),
        subTitle: this.smsCalculatedData.totals.sent_total,
        percentage: 100,
        colorClass: 'gradient-standard',
        percentageColorClass: 'blue',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Entregados').toString(),
        subTitle: this.smsCalculatedData.totals.delivered_total,
        percentage: parseFloat(this.smsCalculatedData.deliveredPercentage).toFixed(0),
        colorClass: 'gradient-success',
        percentageColorClass: 'green',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Clics únicos').toString(),
        subTitle: this.smsCalculatedData.totals.clicked_unique,
        percentage: parseFloat(this.smsCalculatedData.clicksPercentage).toFixed(0),
        colorClass: 'gradient-alert',
        percentageColorClass: 'alert',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Bajas').toString(),
        subTitle: this.smsCalculatedData.totals.unsubscribed_total,
        percentage: parseFloat(this.smsCalculatedData.unsubscribedPercentage).toFixed(0),
        colorClass: 'gradient-danger',
        percentageColorClass: 'danger',
        cols: 3,
      },
    ]
  }

  getDeliveredRatioGraphicGraphicData () {
    const graphicData = new GraphicData(
      this.thatContext.$t('Ratio de entrega'),
      [
        {
          label: this.thatContext.$t('Enviados').toString(),
          value: '<b>' + this.smsCalculatedData.totals.sent_total + '</b> / ',
          total: this.smsCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('Entregados').toString(),
          value: '<b>' + this.smsCalculatedData.totals.delivered_total + '</b> / ',
          total: this.smsCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('Pendientes').toString(),
          value: '<b>' + this.smsCalculatedData.totals.pending + '</b> / ',
          total: this.smsCalculatedData.totals.sent_total
          ,
        },
        {
          label: this.thatContext.$t('No entregados').toString(),
          value: '<b>' + this.smsCalculatedData.totals.undelivered_total + '</b> / ',
          total: this.smsCalculatedData.totals.sent_total,
        },
      ],
      [100, this.smsCalculatedData.deliveredPercentage, this.smsCalculatedData.pendingPercentage, this.smsCalculatedData.undeliveredPercentage],
      [this.thatContext.$t('Enviados').toString(), this.thatContext.$t('Entregados').toString(), this.thatContext.$t('Pendientes').toString(), this.thatContext.$t('No entregados').toString()],
      this.smsCalculatedData.deliveredPercentage,
      '',
      0,
      0,
      true,
      300,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.PENDING,
        Colors.statistics.UNDELIVERED_TOTAL,
      ],
    )
    graphicData.legendProps.fontSize = '10px'

    return graphicData
  }

  getDeliveredRatioGraphicGraphicDataSmall () {
    const graphicData = this.getDeliveredRatioGraphicGraphicData()
    graphicData.legendProps.fontSize = '10px'
    graphicData.legendProps.offsetX = -35
    graphicData.legendProps.offsetY = 5
    return graphicData
  }

  getClicksRatioGraphicData () {
    return new GraphicData(
      this.thatContext.$t('Ratio de CLICS').toString(),
      [
        {
          label: this.thatContext.$t('Enviados').toString(),
          value: '<b>' + this.smsCalculatedData.totals.sent_total + '</b> / ',
          total: this.smsCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('Entregados').toString(),
          value: '<b>' + this.smsCalculatedData.totals.delivered_total + '</b> / ',
          total: this.smsCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('Clics únicos').toString(),
          value: '<b>' + this.smsCalculatedData.totals.clicked_unique + '</b> / ',
          total: this.smsCalculatedData.totals.delivered_total,
        },
      ],
      [100, this.smsCalculatedData.deliveredPercentage, this.smsCalculatedData.clicksPercentage],
      [this.thatContext.$t('Enviados').toString(), this.thatContext.$t('Entregados').toString(), this.thatContext.$t('Clics').toString()],
      this.smsCalculatedData.clicksPercentage,
      this.thatContext.$t('Clics únicos'),
      this.smsCalculatedData.totals.clicked_unique,
      0,
      true,
      280,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.CLICKS,
      ],
    )
  }

  getUnsubscribedRatioBarChartGraphicData () {
    return new GraphicData(
      this.thatContext.$t('Ratio bajas'),
      [
        {
          label: this.thatContext.$t('Entregados').toString(),
          value: '<b>' + this.smsCalculatedData.totals.delivered_total + '</b> / ',
          total: this.smsCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('Bajas').toString(),
          value: '<b>' + this.smsCalculatedData.totals.unsubscribed_total + '</b> / ',
          total: this.smsCalculatedData.totals.delivered_total,
        },
      ],
      [this.smsCalculatedData.totals.delivered_total, this.smsCalculatedData.totals.unsubscribed_total],
      [this.thatContext.$t('Entregados').toString(), this.thatContext.$t('Bajas').toString()],
      this.smsCalculatedData.unsubscribedPercentage,
      '',
      0,
      0,
      true,
      200,
      [
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.UNSUBSCRIBED_TOTAL,
      ],
    )
  }

  getReBouncedRatioGraphicData () {
    return new GraphicData(
      this.thatContext.$t('Ratio de rebotes'),
      [
        {
          label: this.thatContext.$t('Enviados').toString(),
          value: '<b>' + this.smsCalculatedData.totals.sent_total + '</b> / ',
          total: this.smsCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('No entregados').toString(),
          value: '<b>' + this.smsCalculatedData.totals.undelivered_total + '</b> / ',
          total: this.smsCalculatedData.totals.sent_total,
        },
      ],
      [this.smsCalculatedData.totals.sent_total, this.smsCalculatedData.totals.undelivered_total],
      [this.thatContext.$t('Enviados').toString(), this.thatContext.$t('No entregados').toString()],
      this.smsCalculatedData.undeliveredPercentage,
      '',
      0,
      0,
      true,
      200,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.UNDELIVERED_TOTAL,
      ],
    )
  }

  getRedGraphicData () {
    return new GraphicData(
      '',
      [],
      [this.smsCalculatedData.totals.pending, this.smsCalculatedData.totals.rejected_total, this.smsCalculatedData.totals.undelivered_total],
      [this.thatContext.$t('Pendientes').toString(), this.thatContext.$t('Rechazados').toString(), this.thatContext.$t('No entregados').toString()],
      0,
      '',
      0,
      0,
      true,
      250,
      ['#FF4F4F', '#F14D4D', '#E34848', '#D04141', '#B83A3A'],

    )
  }

  getDeliveredGraphicData () {
    return new GraphicData(
        '',
        [],
        [this.smsCalculatedData.totals.delivered_total, this.smsCalculatedData.totals.undelivered_total],
        [this.thatContext.$t('Entregado').toString(), this.thatContext.$t('No entregado').toString()],
        0,
        '',
        0,
        0,
        true,
        200,
        [
          Colors.statistics.DELIVERED_TOTAL,
          Colors.statistics.UNDELIVERED_TOTAL,
        ],
      )
    }

    getInteractionSmsLandingPageGraphicData () {
      return new GraphicData(
        this.thatContext.$t('SMS Landing page').toString(),
        [
          {
            label: this.thatContext.$t('Clics totales').toString(),
            value: '',
            total: this.smsCalculatedData.totals.clicked_total,
          },
          {
            label: this.thatContext.$t('Enlace 1').toString(),
            value: '',
            total: 2,
          },
          {
            label: this.thatContext.$t('Enlace 2').toString(),
            value: '',
            total: 2,
          },
        ],
        [this.smsCalculatedData.totals.clicked_total, 2, 2],
        [this.thatContext.$t('Clics totales').toString(), this.thatContext.$t('Enlace 1').toString(), this.thatContext.$t('Enlace 2').toString()],
        0,
        '',
        0,
        this.smsCalculatedData.totals.clicked_total,
        false,
        200,
      )
    }

    getInteractionTotalGraphicData () {
      const legendData = [
          {
            label: this.thatContext.$t('Clics totales').toString(),
            value: '',
            total: this.smsCalculatedData.totals.clicked_total,
          },
      ]
      const series = [this.smsCalculatedData.totals.clicked_total]
      const labels = [this.thatContext.$t('Clics totales').toString()]

      this.interactionItems.forEach((clickItem) => {
        legendData.push(
          {
            label: this.thatContext.$t(clickItem.type).toString(),
            value: '',
            total: clickItem.clicks,
          },
        )
        series.push(clickItem.clicks)
        labels.push(this.thatContext.$t(clickItem.type).toString())
      })

      return new GraphicData(
        this.thatContext.$t('Interacciones totales').toString(),
        legendData,
        series,
        labels,
        0,
        '',
        0,
        this.smsCalculatedData.totals.clicked_total,
        false,
        200,
        [
          Colors.statistics.CLICKS,
          Colors.statistics.UNSUBSCRIBE_URL,
          Colors.statistics.LANDING_PAGE,
          Colors.statistics.FORM,
          Colors.statistics.ATTACHMENT,
          Colors.statistics.URL,
        ],
      )
    }

    getClickThroughRatioGraphicData () {
      return new GraphicData(
        this.thatContext.$t('Ratio de click-through (CTR)').toString(),
        [
          {
            label: this.thatContext.$t('Entregados').toString(),
            value: '<b>' + this.smsCalculatedData.totals.delivered_total + '</b> / ',
            total: this.smsCalculatedData.totals.sent_total,
          },
          {
            label: this.thatContext.$t('Clics únicos').toString(),
            value: '<b>' + this.smsCalculatedData.totals.clicked_unique + '</b> / ',
            total: this.smsCalculatedData.totals.delivered_total,
          },
        ],
        [this.smsCalculatedData.deliveredPercentage, this.smsCalculatedData.clicksPercentage],
        [this.thatContext.$t('Entregados').toString(), this.thatContext.$t('Clics únicos').toString()],
        this.smsCalculatedData.clicksPercentage,
        this.thatContext.$t('Clics únicos'),
        this.smsCalculatedData.totals.clicked_unique,
        0,
        true,
        300,
        [
          Colors.statistics.DELIVERED_TOTAL,
          Colors.statistics.CLICKS_UNIQUE,
        ],
      )
    }

    getClickToOpenRatioGraphicData () {
      return new GraphicData(
        this.thatContext.$t('Ratio Click-to-Open (CTOR)').toString(),
        [
          {
            label: this.thatContext.$t('Abiertos').toString(),
            value: '',
            total: this.smsCalculatedData.totals.opened_total,
          },
          {
            label: this.thatContext.$t('Abiertos Únicos').toString(),
            value: '',
            total: this.smsCalculatedData.totals.opened_unique,
          },
          {
            label: this.thatContext.$t('Clics').toString(),
            value: '',
            total: this.smsCalculatedData.totals.clicked_total,
          },
          {
            label: this.thatContext.$t('Clics Únicos').toString(),
            value: '',
            total: this.smsCalculatedData.totals.clicked_unique,
          },
        ],
        [
          this.smsCalculatedData.openedTotalPercentage,
          this.smsCalculatedData.openedUniquePercentage,
          this.smsCalculatedData.clicksTotalOverOpenedPercentage,
          this.smsCalculatedData.clicksUniqueOverOpenedPercentage,
        ],
        [
          this.thatContext.$t('Abiertos').toString(),
          this.thatContext.$t('Abiertos Únicos').toString(),
          this.thatContext.$t('Clics').toString(),
          this.thatContext.$t('Clics Únicos').toString(),
        ],
        this.smsCalculatedData.clickToOpenPercentage,
        this.thatContext.$t('Clics totales'),
        this.smsCalculatedData.totals.clicked_total,
        0,
        true,
        300,
        [
          Colors.statistics.OPENED,
          Colors.statistics.OPENED_UNIQUE,
          Colors.statistics.CLICKS,
          Colors.statistics.CLICKS_UNIQUE,
        ],
      )
    }

    getOpenedRatioGraphicData () {
      return new GraphicData(
        this.thatContext.$t('Ratio apertura').toString(),
        [
          {
            label: this.thatContext.$t('Entregados').toString(),
            value: '',
            total: this.smsCalculatedData.totals.delivered_unique,
          },
          {
            label: this.thatContext.$t('Abiertos').toString(),
            value: '',
            total: this.smsCalculatedData.totals.opened_unique,
          },
        ],
        [
          this.smsCalculatedData.totals.delivered_total,
          this.smsCalculatedData.totals.opened_unique,
        ],
        [
          this.thatContext.$t('Entregados').toString(),
          this.thatContext.$t('Abiertos').toString(),
        ],
        this.smsCalculatedData.openedRatioPercentage,
        '',
        '',
        0,
        false,
        300,
        [
          Colors.statistics.DELIVERED_TOTAL,
          Colors.statistics.OPENED,
        ],
      )
    }
}
