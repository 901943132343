import VueApexCharts from 'vue-apexcharts'
export default {
  props: ['graphicData'],
  name: 'SimpleBar',
  components: { apexchart: VueApexCharts },
  data: function () {
   return {
    series: [{
      data: this.graphicData.series,
    }],
   }
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'bar',
          height: this.graphicData.chartHeight,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        colors: this.graphicData.colors,
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['white'],
            fontWeight: 'normal',
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            left: 0,
            top: 0,
            blur: 1,
            color: 'black',
            opacity: 1,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: this.graphicData.labels,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          show: this.graphicData.showLegend,
          markers: {
            radius: 50,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (value, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ':  '
              },
            },
          },
        },
      }
    },
  },
  methods: { },
}
