import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['graphicData'],
  name: 'StackedMultiple',
  components: { apexchart: VueApexCharts },
  data: function () {
   return {
    series: [{
      data: this.graphicData.series,
    }],
   }
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          height: this.graphicData.chartHeight,
          type: 'bar',
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
          toolbar: {
            show: false,
          },
        },
        colors: this.graphicData.colors,
        plotOptions: {
          bar: {
            columnWidth: '75%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: this.graphicData.showLegend,
          markers: {
            radius: 50,
          },
        },
        xaxis: {
          categories: this.graphicData.labels,
          labels: {
            show: false,
            style: {
              colors: this.graphicData.colors,
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(0)
            },
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ':  '
              },
            },
          },
        },
      }
    },
  },
  watch: {
    graphicData: {
      handler (nv, ov) {
        let max = 0
        nv.series.map(d => {
          max = Math.max(max, d)
        })

        this.chartOptions.yaxis.max = max === 0 ? 1 : max
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {

  },
}
