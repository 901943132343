import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'

export default {
  props: ['graphicData'],
  name: 'InteractionBar',
  components: { SimpleBar },
  data: function () {
   return {
   }
  },
  methods: {

  },
  mounted () {

  },
}
