export class CalculatedData {
  deliveredPercentage = 0
  clicksPercentage = 0
  unsubscribedPercentage = 0
  undeliveredPercentage = 0
  pendingPercentage = 0
  reboundPercentage = 0
  clickToOpenPercentage = 0
  openedRatioPercentage = 0
  openedPercentage = 0
  openedTotalPercentage = 0
  openedUniquePercentage = 0
  clicksTotalOverOpenedPercentage = 0
  clicksUniqueOverOpenedPercentage = 0
  totals = {}
}
