import { Colors } from '@/classes/colors/Colors'
import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['campaginDataSource'],
  name: 'DeliveredSms',
  components: { apexchart: VueApexCharts },
  data: function () {
    return {
      deliveredPercentage: 0,
      unsubscribedPercentage: 0,
      unsubscribedTotal: 0,
      series: [],
      ready: true,
      chartDataInfo: [],
      colors: Colors,
      chartOptions: {
        chart: {
          // offsetX: -150,
        },
        legend: {
          show: false,
        },
        colors: [Colors.colorChartDelivered, Colors.colorChartUndelivered, Colors.colorChartOpened],
        labels: [],
      },
      showEmpty: false,
      emptySeries: [100],
      emptyChartOptions: {
        labels: [''],
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartEmptyBar],
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: undefined,
                formatter: function (val) {
                  return '' // val + '%'
                },
              },
            },
          },
        },
      },
    }
  },
  methods: {
    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.undelivered_total) + parseInt(data.rejected_total) + parseInt(data.expired_total)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    calculatePending (data) {
      let pending = parseInt(data.sent_total) - parseInt(data.delivered_total) - parseInt(data.undelivered_total)
      if (pending < 0) {
        pending = 0
      }
      return pending
    },
    setPieChart (totals) {
      totals.delivered_total = parseInt(totals.delivered_total)
      totals.undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.pending = this.calculatePending(totals)
      this.series = [totals.delivered_total, totals.undelivered_total, totals.pending]

      this.deliveredPercentage = totals.delivered_total === 0 ? totals.delivered_total : ((totals.delivered_total / totals.sent_total) * 100).toFixed(0)
      const unsubscribedPercentage = (totals.unsubscribed_total / totals.delivered_total) * 100
      this.unsubscribedPercentage = unsubscribedPercentage > 0 ? unsubscribedPercentage.toFixed(0) : '0'
      this.unsubscribedTotal = parseInt(totals.unsubscribed_total)

      const undeliveredPercentage = ((totals.undelivered_total / totals.sent_total) * 100).toFixed(0)
      const pendingPercentage = ((totals.pending / totals.sent_total) * 100).toFixed(0)

      this.chartDataInfo = [
        {
          label: this.$t('No entregados').toString(),
          value: undeliveredPercentage + '%',
          color: Colors.colorChartUndelivered,
        },
        {
          label: this.$t('Pendientes').toString(),
          value: pendingPercentage + '%',
          color: Colors.colorChartOpened,
        },
      ]

      this.chartOptions.labels = [
        this.$t('Entregados').toString() + ' ' + this.deliveredPercentage + '%',
        this.$t('No Entregados').toString() + ' ' + undeliveredPercentage + '%',
        this.$t('Pendientes').toString() + ' ' + pendingPercentage + '%',
      ]

      this.resetReady()
    },
    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
  },
  created () {
    const additional = this.campaginDataSource.additional ? JSON.parse(JSON.stringify(this.campaginDataSource.additional)) : null
    if (this.campaginDataSource.total > 0) {
      additional && this.setPieChart(additional[0])
    } else {
      this.showEmpty = true
    }
  },
}
