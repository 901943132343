import { CalculatedData } from '@/classes/stats/Calculations/CalculatedData'

export class SmsCalculatedData extends CalculatedData {
  // eslint-disable-next-line complexity
  calculate (campaignDataSource) {
    const additional = JSON.parse(JSON.stringify(campaignDataSource.additional))
    if (!campaignDataSource.total) {
      return
    }
    this.totals = additional[0]

    this.totals.delivered_total = parseInt(this.totals.delivered_total)
    this.totals.delivered_unique = parseInt(this.totals.delivered_unique)
    this.totals.sent_total = parseInt(this.totals.sent_total)

    this.totals.opened_total = parseInt(this.totals.opened_total)
    this.totals.opened_unique = parseInt(this.totals.opened_unique)

    // eslint-disable-next-line no-nested-ternary
    this.openedTotalPercentage = this.totals.delivered_total
      ? ((this.totals.opened_unique * 100) / this.totals.delivered_total) >= 100
            ? (100).toFixed(2)
            : ((this.totals.opened_unique * 100) / this.totals.delivered_total).toFixed(2)
      : 0

    this.openedUniquePercentage = this.totals.opened_total
      ? ((this.totals.opened_unique * 100) / this.totals.opened_total).toFixed(2)
      : 0

    this.deliveredPercentage = this.totals.delivered_total
      ? ((this.totals.delivered_total / this.totals.sent_total) * 100).toFixed(0)
      : 0

    // eslint-disable-next-line no-nested-ternary
    this.clicksPercentage = this.totals.delivered_total
      ? ((this.totals.clicked_unique * 100) / (this.totals.delivered_total) >= 100
        ? (100).toFixed(2)
        : ((this.totals.clicked_unique * 100) / this.totals.delivered_total).toFixed(2))
      : 0
    // eslint-disable-next-line no-nested-ternary
    this.clicksTotalPercentage = this.totals.delivered_total
      ? ((this.totals.clicked_total * 100) / (this.totals.delivered_total) >= 100
        ? (100).toFixed(2)
        : ((this.totals.clicked_unique * 100) / this.totals.delivered_total).toFixed(2))
      : 0

    const unsubscribedPercentage = (this.totals.unsubscribed_total * 100) / this.totals.delivered_total
    this.unsubscribedPercentage = unsubscribedPercentage > 0 ? unsubscribedPercentage.toFixed(2) : '0'


    this.totals.undelivered_total = this.calculateUndeliveredTotal(this.totals)
    this.totals.pending = this.calculatePending(this.totals)

    this.undeliveredPercentage = this.totals.sent_total ? ((this.totals.undelivered_total / this.totals.sent_total) * 100).toFixed(0) : 0
    this.pendingPercentage = this.totals.sent_total ? ((this.totals.pending / this.totals.sent_total) * 100).toFixed(0) : 0

    this.clickToOpenPercentage = this.totals.opened_unique ? ((this.totals.clicked_unique * 100) / this.totals.opened_unique).toFixed(2) : 0
    this.openedRatioPercentage = this.totals.delivered_unique ? ((this.totals.opened_unique * 100) / this.totals.delivered_unique).toFixed(2) : 0

    // eslint-disable-next-line no-nested-ternary
    this.clicksTotalOverOpenedPercentage = this.totals.delivered_total
      ? ((this.totals.clicked_unique * 100) / this.totals.delivered_total) >= 100
            ? (100).toFixed(2)
            : ((this.totals.clicked_unique * 100) / this.totals.delivered_total).toFixed(2)
      : 0
    this.clicksUniqueOverOpenedPercentage = this.totals.clicked_total ? ((this.totals.clicked_unique * 100) / this.totals.clicked_total).toFixed(2) : 0
  }

  calculateUndeliveredTotal (data) {
    let fixedUndeliveredTotal = parseInt(data.undelivered_total) + parseInt(data.rejected_total) + parseInt(data.expired_total)
    if (fixedUndeliveredTotal < 0) {
      fixedUndeliveredTotal = 0
    }
    return fixedUndeliveredTotal
  }

  calculatePending (data) {
    let pending = parseInt(data.sent_total) - parseInt(data.delivered_total) - parseInt(data.undelivered_total)
    if (pending < 0) {
      pending = 0
    }
    return pending
  }
}
