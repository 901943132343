import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['graphicData'],
  name: 'StackedColumn',
  components: { apexchart: VueApexCharts },
  data: function () {
   return {
    series: [],
   }
  },
  computed: {
    chartOptions () {
      const graphicData = this.graphicData

      this.series = []
      graphicData.series.forEach((serie, index) => {
        this.series.push({
          name: graphicData.labels[index],
          data: [serie],
        })
      })
      return {
        colors: graphicData.colors,
        chart: {
          type: 'bar',
          height: graphicData.chartHeight,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [''],
        },
        tooltip: {
          x: {
            show: false,
          },
        },
        legend: {
          position: 'bottom',
          markers: {
            radius: 50,
          },
        },
        fill: {
          opacity: 1,
        },
      }
    },
  },
  methods: {

  },
  mounted () {

  },
}
