import { Colors } from '@/classes/colors/Colors'
import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['campaginDataSource', 'campaign', 'height'],
  name: 'UserInteractionSms',
  components: { apexchart: VueApexCharts },
  data: function () {
    return {
      ready: true,
      heightDef: this.height ? this.height : 220,
    }
  },
  computed: {
    barChartOptions () {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        annotations: {
          position: 'front',
          yaxis: [{
            label: {
              borderColor: '#c2c2c2',
              text: this.$t('Media del total de los entregados').toString(),
              },
            },
          ],
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value
            },
          },
        },
        xaxis: {
          categories: [],
        },
        series: [
          {
            name: this.$t('Clics'),
            data: [],
          },
          {
            name: this.$t('Bajas'),
            data: [],
          },
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        colors: [Colors.colorChartUniqueClicks, Colors.colorChartUnsubscribedLanding],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (val) {
              return val
          },
        },
      }
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    locale () {
      this.setBarChart(this.campaginDataSource.data)
    },
  },
  methods: {
    setBarChart (data) {
      this.barChartOptions.series[0].data = []
      this.barChartOptions.series[1].data = []
      this.barChartOptions.xaxis.categories = []
      data.forEach(data => {
        const totalDelivered = parseInt(data.delivered_total)
        const totalClicked = parseInt(data.clicked_total)
        const totalUnsubscribed = parseInt(data.unsubscribed_unique)
        const percentageClicks = (totalClicked / totalDelivered).toFixed(1)
        const percentageUnsubscribed = (totalUnsubscribed / totalDelivered).toFixed(1)

        this.barChartOptions.series[0].data.push(percentageClicks)
        this.barChartOptions.series[1].data.push(percentageUnsubscribed)
        this.barChartOptions.xaxis.categories.push(data.sending_id)
      })
      this.resetReady()
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
  },
  created () {
    console.log(this.campaginDataSource)
    this.setBarChart(this.campaginDataSource.data)
  },
}
