import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['graphicData'],
  name: 'Pie',
  components: { apexchart: VueApexCharts },
  data: function () {
   return {
   }
  },
  methods: {

  },
  computed: {
    chartOptions () {
      const graphicData = this.graphicData
      return {
        chart: {
          height: graphicData.chartHeight,
          type: 'donut',
        },
        dataLabels: {
          enabled: graphicData.enabledDataLabels,
        },
        tooltip: {
          enabled: graphicData.enabledTooltip,
        },
        colors: graphicData.colors,
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
              },
              total: {
                show: true,
                label: graphicData.centerTitle,
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return graphicData.centerValue
                },
              },
            },
          },
        },
        legend: {
          show: graphicData.showLegend,
        },
        labels: graphicData.labels,
      }
    },
  },
  mounted () {
  },
}
